/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux'
import { getStats, getSupply } from '../redux/actions';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9, Filter9Plus } from '@material-ui/icons';


const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#f0f8ff',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        display: "inline",
        float: "left",
        clear: "both",
        margin: 0,
        padding: 0
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    subheader: {
        backgroundColor: "lightBlue",
        fontSize: 14,
        color: "#000",
        "&.MuiListSubheader-inset": { marginBottom: 12 },
        textAlign: 'left'
    },
    inline: {
        display: 'inline',
    },
    ul: {
        "&.MuiListItem-divider": {
            borderBottom: "2px solid rgba(0,0,0,0.1)"
        },
        margin: 0,
        padding: 0,
    },
    listRoot: {
        maxHeight: "200px",
        overflow: 'auto',
        width: "100%",
        backgroundColor: "#fff",
        marginTop: 12,
        "& .MuiListItem-root": {
            "&:hover": {
                color: "blue"
            },
            "&.MuiListItem-divider": {
                borderBottom: "2px solid rgba(0,0,0,0.1)"
            }
        }
    },
    listItemText: {
        "& .MuiListItemText-primary": {
            fontSize: "1.2rem"
        }
    },
    itemDuplicated: {
        background: "rgba(144, 238, 144, 0.1)",
        "& .MuiListItem-root": {
            "&:hover": {
                color: "rgba(144, 238, 144,1)"
            },
            "&.MuiListItem-divider": {
                borderBottom: "2px solid rgba(144, 238, 144,1)"
            }
        }

    },
    heading: {
        float: "left"
    },
    secondaryHeading: {
        position: "absolute",
        right: "50px"
    },
}));

function RarityList({ contract, name, stats, totalSupply, usedSupply, getStats, getSupply }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel0');
    //const MS = 60000;

    /*
    React.useEffect(() => {
        getStats()
        const interval = setInterval(() => {
            getSupply()
        }, MS);
        return () => clearInterval(interval);
    }, [getStats, getSupply])
    */
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const navigate = (token) => {
        const href = `https://opensea.io/assets/${contract}/${token}`
        window.open(href, '_blank');
    }

    const showRarityWithoutPct = (rarity) => {
        return rarity.split('(')[0]
    }

    const getStatsAndSupply = () => {
        getStats()
        getSupply()
    }

    const getIconByNumber = (numb) => {
        switch (numb) {
            case 1:
                return Filter1
            case 2:
                return Filter2;
            case 3:
                return Filter3;
            case 4:
                return Filter4;
            case 5:
                return Filter5;
            case 6:
                return Filter6;
            case 7:
                return Filter7;
            case 8:
                return Filter8;
            case 9:
                return Filter9;
            default:
                return Filter9Plus
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: "end" }}>
                    <Typography align="center" variant="h4" gutterBottom className={classes.title}>
                        {`Trades by rarity`}
                    </Typography>
                    <Button disabled={name === undefined} onClick={() => getStatsAndSupply()} variant="contained" color="primary">
                        <RefreshIcon />
                    </Button>
                    <Typography>Minted: {totalSupply}, used: {usedSupply}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {stats.map((category, i) => (
                        <Grid key={"grid" + i} item md={12}>
                            <Accordion square expanded={expanded === "panel" + i} onChange={handleChange('panel' + i)}>
                                <AccordionSummary aria-controls={"panel" + i + "d-content"} id={"panel" + i + "d-header"} expandIcon={<ExpandMoreIcon />}>
                                    <Typography
                                        className={classes.heading}>{React.createElement(getIconByNumber(i + 1), null)}. Category: <b>{category.trait_category}</b> Trait: <b>{category.trait}</b> Levels: <b>{category.trait_levels}</b>
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>{showRarityWithoutPct(category.trait_rarity)}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {expanded === "panel" + i &&
                                        <List className={classes.listRoot}>
                                            {category.tokens.map((token) => (
                                                <ListItem className={token.duplicate ? classes.itemDuplicated : ""} dense divider button key={`item-${category.rarity}-${token.tokenid}`} onClick={() => navigate(token.tokenid)}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={`token ${token.tokenid}`}
                                                            src={""}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={classes.listItemText}
                                                        primary={token.duplicate ? `Token ${token.tokenid} has ${token.duplicated} rare traits` : `Token ${token.tokenid}`}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    Price: {token.price_sell} Highest bid: {token.price_buy}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }

                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = state => {
    return {
        contract: state.contract,
        name: state.name,
        stats: state.stats,
        totalSupply: state.totalSupply,
        usedSupply: state.usedSupply
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStats: () => dispatch(getStats()),
    getSupply: () => dispatch(getSupply())
})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RarityList)