import * as actions from './actions';

const initialState = {
    loading: false,
    requiredFunctions: ["baseURI", "tokenURI", "totalSupply"],
    requiredStartIndex: undefined,
    tokenUri: "",
    name: undefined,
    totalSupply: 0,
    usedSupply: 0,
    verified: undefined,
    functions: [],
    contract: undefined,
    page: "start",
    stats: [],
    error: "",
    uniqueTokens: [],
    token: undefined,
}

export default function rootReducer(state = initialState, action) {
    switch (action.type) {

        case actions.API_ERROR: {
            const { message } = action.payload
            return {
                ...state,
                loading: false,
                error: message
            };
        }

        case actions.GET_CONTRACT_INFO: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.GOT_CONTRACT_INFO: {
            const { name, contract, totalSupply, functions, token0, token0Uri } = action.payload

            const itemToLookForURI = ["baseURI", "tokenURI"];
            const verifiedURI = functions.some(r => itemToLookForURI.includes(r))

            const itemToLookForSupply = ["totalSupply"];
            const verifiedSupply = functions.some(r => itemToLookForSupply.includes(r))

            const verifiedIndex = token0 === 0 || token0 === 1
            const verifiedUri = !token0Uri.toLowerCase().includes("ipfs")

            const verified = (Number(verifiedURI) + Number(verifiedSupply) + Number(verifiedIndex) + Number(verifiedUri) === 4)

            return {
                ...state,
                name: name,
                contract: contract,
                totalSupply: totalSupply,
                verified: verified,
                functions: functions,
                loading: false,
                error: "",
                requiredStartIndex: token0,
                tokenUri: token0Uri
            };
        }

        case actions.SET_PAGE: {
            const { page } = action.payload
            return {
                ...state,
                page: page
            };

        }

        case actions.START_GETTING_STATS: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.GOT_STATS: {

            const { stats, snipe } = action.payload
            let tokens = [];

            //Show all unique tokens
            const fetchTokens = stats.map(category => {
                tokens.concat(category.tokens)
                return [...new Set(tokens)]
            })

            //all tokens non unique view
            const allItems = stats.map(category => {
                return (
                    category.tokens.map(token => { return token })
                )
            });

            const allPrices = snipe.map(category => {
                return (
                    category.tokens_sales.map(token => { return token })
                )
            })

            var allItemsFlattened = [].concat.apply([], allItems);
            var allPricesFlattened = [].concat.apply([], allPrices);

            //Tokens in more than one category
            const foundDuplicate = allItemsFlattened.filter((a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i);
            //console.log(foundDuplicate)
            const counts = {};
            allItemsFlattened.forEach((x) => {
                counts[x] = (counts[x] || 0) + 1;
            });

            const tokensRefined = stats.map(category => {
                return (
                    category.tokens.map(token => {
                        //console.log(token)
                        let item = allPricesFlattened.filter(token_sales => token_sales.token.toString() === token.toString())

                        if (item.length !== 0) {
                            const count = foundDuplicate.filter(dup => dup === token)

                            if (count.length > 0) {
                                return { tokenid: token, duplicate: true, ...item[0], duplicated: counts[token] /*count.length*/ }
                            }

                            return { tokenid: token, ...item[0] }
                        }

                        else {
                            const count = foundDuplicate.filter(dup => dup === token)
                            if (count.length > 0) {
                                return { tokenid: token, duplicate: true, price_buy: "", price_sell: "", duplicated: counts[token.toString()] /*count.length*/ }
                            }
                            return { tokenid: token, price_buy: "", price_sell: "" }
                        }
                    }))
            })

            const statsRefined = stats.map((category, i) => {
                category.tokens = tokensRefined[i]
                return category
            })
            let usedSupply
            if (stats.length > 0) {
                usedSupply = stats[0].trait_rarity.split(/[ ,]+/)[2]
            }
            return {
                ...state,
                stats: stats,
                refinedStats: statsRefined,
                uniqueTokens: fetchTokens,
                loading: false,
                usedSupply: usedSupply,
                error: ""
            };
        }

        case actions.GOT_SUPPLY: {
            const { totalSupply } = action.payload

            return {
                ...state,
                totalSupply: totalSupply,
                error: ""
            };
        }
        default:
            return { ...state }
    }
}

