import './App.css';
import React from 'react';
import { connect } from 'react-redux'
import { setPage } from './redux/actions';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import RarityList from "./components/RarityList"
import AddContract from "./components/AddContract"
import Password from "./components/Password"
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

const theme = createTheme({
  palette: {
    secondary: {
      main: green[500],
      contrastText: "#fff"
    },
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "100%"
  },
  title: {
    flex: 1
  },
  height: {
    height: "100%"
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#000"
  }
}));

function App({ page, contract, name, verified, error, loading }) {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Container maxWidth="md" className={classes.height}>
          <AppBar className={classes.appBar} position="static">
            <Toolbar>
              {contract === undefined && <Typography variant="h4">NFT Sniper</Typography>}
              {contract !== undefined && (
                <Typography align="left" variant="h6" className={classes.title}>
                  {contract} {(verified) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon color="error" />}
                </Typography>
              )}
              <Typography align="right" variant="h6">
                {loading ? <CircularProgress /> : name}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid className={classes.height} item md={12}>
              <Paper className={classes.paper}>
                {page === "start" && <Password />}
                {page === "add" && <AddContract />}
                {page === "rarityList" && <RarityList />}
                <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
              </Paper>
            </Grid>
          </Grid>

        </Container>
      </div>
    </MuiThemeProvider>
  );
}

//export default App;

const mapStateToProps = state => {
  return {
    page: state.page,
    contract: state.contract,
    name: state.name,
    verified: state.verified,
    error: state.error,
    loading: state.loading
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setPage(page)),
})


export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)