import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

//import reportWebVitals from './reportWebVitals';
import configureStore from './redux/store';
import Root from './root'
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
  <Root store={store} />
  </React.StrictMode>,
  document.getElementById('root')
)
