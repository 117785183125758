/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux'
import { getContractInfo, setPage } from '../redux/actions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "350px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    green: {
        background: "green"
    }
}));


function AddContract({ getContractInfo, setPage, requiredFunctions, loading, verified, functions, requiredStartIndex, tokenUri }) {
    const classes = useStyles();
    const [contract, setContract] = React.useState("");
    const [indexConfirmed, setIndexConfirmed] = React.useState(false);
    const [ipfs, setIpfs] = React.useState(false);
    const [checks, setChecks] = React.useState([false, false, false]);
    const [tick, setTick] = React.useState(0);

    React.useEffect(() => {

        const checkContract = () => {
            const newArray = [...checks];
            const checked = functions.includes(requiredFunctions[tick])
            newArray[tick] = checked
            setTick(tick + 1)
            setChecks(newArray)
        }

        const checkStartIndex = () => {
            if (requiredStartIndex === 0 || requiredStartIndex === 1) {
                setIndexConfirmed(true)
            }
            setTick(tick + 1)
        }

        const checkIPFSStorage = () => {
            if (!tokenUri.toLowerCase().includes("ipfs")) {
                setIpfs(true)
            }
            setTick(tick + 1)
        }

        const intervalID = setTimeout(() => {
            if (verified && tick < 3) {
                checkContract()
            }
            if (verified && tick === 3) {
                checkStartIndex()
            }
            if (verified && tick === 4) {
                checkIPFSStorage()
            }
        }, 500);
        return () => clearInterval(intervalID);
    }, [functions, requiredFunctions, tick, verified, checks, requiredStartIndex, tokenUri]);

    const onContractChange = (event) => {
        setContract(event.target.value)
    }

    const onCheck = () => {
        getContractInfo(contract)
    }

    const onSelect = () => {
        setPage('rarityList')
    }

    return (
        <>
            <Typography variant="h6" gutterBottom>Insert contract id</Typography>
            <Grid item md={12}>
                <FormControl className={classes.formControl}>
                    <TextField id="outlined-basic-contract" label="Contract id" variant="outlined" onChange={onContractChange} />
                </FormControl>
            </Grid>

            <Grid item md={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    {((functions.length === 0) || (functions.length > 0 && verified)) &&
                        <Button
                            disabled={contract === ""}
                            onClick={(!verified) ? () => onCheck() : () => onSelect()}
                            variant="contained"
                            color={(!verified) ? "primary" : "secondary"}>
                            {verified ? "Select" : "Check"}
                        </Button>
                    }
                    {functions.length > 0 && !verified &&
                        <p style={{ color: "red", fontWeight: "bold" }}>Not able to display metadata for contract</p>
                    }
                    <div style={{ marginTop: "0.7rem" }}>
                        {requiredFunctions.map((func, i) => {

                            return (
                                <div key={i} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginBottom: '0.5rem'
                                }}>

                                    {checks[i] === false ?
                                        <CancelIcon color="error" />
                                        :
                                        <CheckCircleIcon style={{ color: "green" }} />
                                    }
                                    <span style={{ marginLeft: "0.3rem" }}>Contract includes {func} function</span>

                                </div>
                            )
                        })}
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: '0.5rem'
                        }}>
                            {indexConfirmed === false ?
                                <CancelIcon color="error" />
                                :
                                <CheckCircleIcon style={{ color: "green" }} />
                            }
                            <span style={{ marginLeft: "0.3rem" }}>Contract index must start with 0 or 1</span>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: '0.5rem'
                        }}>
                            {ipfs === false ?
                                <CancelIcon color="error" />
                                :
                                <CheckCircleIcon style={{ color: "green" }} />
                            }
                            <span style={{ marginLeft: "0.3rem" }}>Metadata URI is not IPFS</span>
                        </div>
                    </div>
                </FormControl>
            </Grid>
        </>
    );
}

const mapStateToProps = state => {
    return {
        requiredFunctions: state.requiredFunctions,
        requiredStartIndex: state.requiredStartIndex,
        functions: state.functions,
        verified: state.verified,
        loading: state.loading,
        tokenUri: state.tokenUri
    }
}


const mapDispatchToProps = (dispatch) => ({
    getContractInfo: (contract) => dispatch(getContractInfo(contract)),
    setPage: (page) => dispatch(setPage(page))
})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddContract)