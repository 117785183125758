/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux'
import { setPage } from '../redux/actions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "350px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    green: {
        background: "green"
    }
}));


function Password({ setPage }) {
    const password = "MintNFT"
    const [entered, setEntered] = React.useState("")
    const classes = useStyles();

    const onPasswordChange = (event) => {
        setEntered(event.target.value)
    }

    const onEnter = () => {
        if (entered === password)
            setPage('add')
    }

    return (
        <>
            <Typography variant="h6" gutterBottom>Please login</Typography>
            <Grid item md={12}>
                <FormControl className={classes.formControl}>
                    <TextField id="outlined-basic-contract" label="Password" variant="outlined" onChange={onPasswordChange} />
                </FormControl>
            </Grid>

            <Grid item md={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Button
                        disabled={entered === ""}
                        onClick={() => onEnter()}
                        variant="contained"
                        color={"secondary"}>
                        {"Enter"}
                    </Button>
                </FormControl>
            </Grid>
        </>
    );
}


const mapDispatchToProps = (dispatch) => ({
    setPage: (page) => dispatch(setPage(page))
})

export default
    connect(
        null,
        mapDispatchToProps
    )(Password)