import axios from 'axios';

//Gets information from the contract: name, functions
export const GET_CONTRACT_INFO = 'GET_CONTRACT_INFO'
export const GOT_CONTRACT_INFO = 'GOT_CONTRACT_INFO'

export const SET_PAGE = 'SET_PAGE'

export const START_GETTING_STATS = 'START_GETTING_STATS'
export const GOT_STATS = 'GOT_STATS'

export const GOT_SUPPLY = 'GOT_SUPPLY'

export const API_ERROR = 'API_ERROR'




/*0xD136Ad9eB0A53633156972e3f746aB10d6DceD7d Can not call metadata*/
const getNextTokensUrl = (fillSize) => `https://api.metawebservice.eu/metadata/${fillSize}`
//const getExistingTokenUrl = (address, skip, pagesize) => `https://api.metawebservice.eu/${address}/${skip}/${pagesize}`
const getStatsUrl = (address) => `https://api.metawebservice.eu/stat/${address}`
//const getMintSnipeUrl = (address, number) => `https://api.metawebservice.eu/snipe/${address}/${number}`
/*0x49055997b76bCE07a6aA8fAA61a97b42731b9Cb5 Supply mot working*/
const getContractDetailsUrl = (address) => `https://api.metawebservice.eu/supply/${address}`

/*
export const fillCsvFile = () => {
  console.log("Called fillCsvFile")
  return async function (dispatch, getState) {
    const { contract, fillSize } = getState();
    dispatch({
      type: START_FILLING_CSV,
    });

    const payload = { contract: contract };
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    let metadata = []
    let i = 1
    do {
      const response = await axios.post(getNextTokensUrl(fillSize), payload, config);
      if (response.status === 200) {
        const res = response.data
        console.log(response)
        metadata = res.metadata
        dispatch({
          type: FILLING_CSV,
          payload: { metadata, i }
        });
      }
      i = i + 1
    }
    while (metadata.length > 5)

    dispatch({
      type: FINISHED_FILLING_CSV,
    });

  }
}
*/
export const getStats = () => {
  return async function (dispatch, getState) {
    const { contract } = getState();
    dispatch({
      type: START_GETTING_STATS,
    });

    try {
      const payload = { contract: contract };
      let config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      //let token = await getTokenBaseUrl(contract)
      await axios.post(getNextTokensUrl(1), payload, config);

      //This shows stats only
      let resp = await axios.get(getStatsUrl(contract));
      //let resp1 = await axios.get(getMintSnipeUrl(contract, Math.floor(Math.random() * 10000)));

      let snipe = []
      if (resp.status === 200) {
        const res = resp.data
        const stats = res.stat

        //if (resp1.status === 200) {
        //  const res = resp1.data
        //  snipe = res.snipe

        dispatch({
          type: GOT_STATS,
          payload: { stats, snipe }
        });
        //}
      }
    }
    catch (err) {
      const message = err.message
      dispatch({
        type: API_ERROR,
        payload: { message }
      });
    }
  }
}
export const getContractInfo = (contract) => {

  return async function (dispatch) {

    dispatch({
      type: GET_CONTRACT_INFO,
    });

    try {
      const contractDetails = await getContractDetails(contract)
      const { name, totalSupply, functions, token0, token0Uri } = contractDetails

      dispatch({
        type: GOT_CONTRACT_INFO,
        payload: { name, contract, totalSupply, functions, token0, token0Uri }
      });
    }

    catch {
      const message = "API Failure"

      dispatch({
        type: API_ERROR,
        payload: { message }
      });
    }
  }
}
const getContractDetails = async (contract) => {
  try {
    const apiUrl = getContractDetailsUrl(contract)
    const response = await axios.get(apiUrl);
    if (response.status === 200) {
      const details = response.data
      return details
    }
  }
  catch (err) {
    return undefined
  }
}
export const getSupply = () => {

  return async function (dispatch, getState) {
    const { contract } = getState();
    try {
      const contractDetails = await getContractDetails(contract)
      const { totalSupply } = contractDetails

      dispatch({
        type: GOT_SUPPLY,
        payload: { totalSupply }
      });
    }
    catch {
      const message = "API Failure"
      dispatch({
        type: API_ERROR,
        payload: { message }
      });
    }
  }
}
export const setPage = (page) => {
  return async function (dispatch) {
    dispatch({
      type: SET_PAGE,
      payload: { page }
    });
  }
}
/*
export const getTokens = () => {

  return async function (dispatch, getState) {

    const { contract, tokens, pageSize } = getState();
    const len = tokens.length

    dispatch({
      type: GET_TOKENS,
    });

    try {

      const payload = { contract: contract };
      let config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.post(getNextTokensUrl(), payload, config);

      let _pageSize = pageSize
      let res = [];
      let metadata = []
      let skip = Math.floor(len / _pageSize);
      console.log(skip)

      do {
        console.log("calling api with startvalue ", skip)
        const apiUrl = getExistingTokenUrl(contract, skip, _pageSize)
        const response = await axios.get(apiUrl);

        if (response.status === 200) {
          metadata = response.data.metadata
          res = res.concat(metadata)
          dispatch({
            type: GOT_TOKENS,
            payload: { res }
          });
        }
        skip = skip + 1
        console.log(metadata.length)
      }
      while (metadata.length === pageSize)
    }
    catch (err) {
      console.log(err)
    }
  }
}
*/



